export default () => {

    const newestSensorValue = (value, value2) => {
        const date = new Date(value['_time']);
        const date2 = new Date(value2['_time']);
      
        if (date < date2) {
          return value2;
        }
      
        return value;
    }

    /* eslint-disable-next-line no-restricted-globals */
    self.addEventListener('message', e => {
        if (!e) { return; }

        const sensorValues = e.data[0];

        if (!sensorValues) { return; }

        let widthValues = sensorValues.widths;
        let deepnessLeftBackValues = sensorValues.deepnessLeftBackValues;
        let deepnessLeftFrontValues = sensorValues.deepnessLeftFrontValues;
        let deepnessRightBackValues = sensorValues.deepnessRightBackValues;
        let deepnessRightFrontValues = sensorValues.deepnessRightFrontValues;
        let temperatureLeftValues = sensorValues.temperatureLeftValues;
        let temperatureMiddleValues = sensorValues.temperatureMiddleValues;
        let temperatureRightValues = sensorValues.temperatureRightValues;
        let temperatureREMValues = sensorValues.temperatureREMValues;

        const existingWidths = e.data[1];
        const existingDeepnesses = e.data[2];
        const existingTemperatures = e.data[3];
        const reverseLeftDeepness = e.data[4];
        const reverseRightDeepness = e.data[5];
        const leftDeepnessSensorDifference = e.data[6];
        const rightDeepnessSensorDifference = e.data[7];
        const deepnessFilterMax = e.data[8];
        const deepnessFilterMin = e.data[9];

        let widths = [];

        for (let index = 0; index < widthValues.length; index++) {
            let width = widthValues[index];
            let date = new Date(width['_time']);
            const timezoneOffset = date.getTimezoneOffset() / 60;
            date.setHours(date.getHours() - timezoneOffset);
            const dateString = date.toISOString().substring(0, 19);

            const sameExist = existingWidths.find(width => width.time.substring(0, 19) === dateString);

            if (sameExist != null) {
                postMessage(true);
                continue;
            }

            const latitude = width['location'].latitude;
            const longitude = width['location'].longitude;

            let widthData = {
                time: dateString,
                latitude: latitude,
                longitude: longitude,
                width: width['_value']
            };

            widths.push(widthData);
        }

        postMessage(['/width', widths]);

        let maxLength = Math.max(deepnessLeftFrontValues.length, deepnessRightFrontValues.length);

        let deepnesses = [];

        for (let index = 0; index < maxLength; index++) {
            let leftFrontValue;
            let rightFrontValue;
            let leftBackValue;
            let rightBackValue;
            let leftValue;
            let rightValue;

            if (index < deepnessLeftFrontValues.length) {
                leftFrontValue = parseFloat(deepnessLeftFrontValues[index]['_value']);
            }

            if (index < deepnessRightFrontValues.length) {
                rightFrontValue = parseFloat(deepnessRightFrontValues[index]['_value']);
            }

            if (index < deepnessLeftBackValues.length) {
                leftBackValue = parseFloat(deepnessLeftBackValues[index]['_value']);
            }

            if (index < deepnessRightBackValues.length) {
                rightBackValue = parseFloat(deepnessRightBackValues[index]['_value']);
            }

            if (leftFrontValue && leftBackValue) {
                let value;

                if (reverseLeftDeepness) {
                    value = leftFrontValue - leftBackValue - leftDeepnessSensorDifference;
                }
                else {
                    value = leftBackValue - leftFrontValue - leftDeepnessSensorDifference;
                }

                if (value >= deepnessFilterMin && value <= deepnessFilterMax) {
                    leftValue = value;
                }
            }

            if (rightFrontValue && rightBackValue) {
                let value;

                if (reverseRightDeepness) {
                    value = rightFrontValue - rightBackValue - rightDeepnessSensorDifference;
                }
                else {
                    value = rightBackValue - rightFrontValue - rightDeepnessSensorDifference;
                }

                if (value >= deepnessFilterMin && value <= deepnessFilterMax) {
                    rightValue = value;
                }
            }

            if (leftValue == null) {
                postMessage(true);
            }

            if (rightValue == null) {
                postMessage(true);
            }

            if (leftValue == null && rightValue == null) {
                continue;
            }

            let deepness = {};

            if (leftValue != null) {
                const newestValue = newestSensorValue(deepnessLeftFrontValues[index], deepnessLeftBackValues[index]);
                let date = new Date(newestValue['_time']);
                const timezoneOffset = date.getTimezoneOffset() / 60;
                date.setHours(date.getHours() - timezoneOffset);
                const time = date.toISOString().substring(0, 19);

                const sameExist = existingDeepnesses.find(deepness => deepness.time === time
                                                                        && deepness.sensor_id === 0);

                if (sameExist == null) {
                    const deepnessValue = leftValue;
                    const latitude = newestValue['location'].latitude;
                    const longitude = newestValue['location'].longitude;

                    deepness.deepness = deepnessValue;
                    deepness.sensor_id = 0;
                    deepness.latitude = latitude;
                    deepness.longitude = longitude;
                    deepness.time = time;

                    deepnesses.push(Object.assign({}, deepness));
                }
                else {
                    postMessage(true);
                }
            }

            if (rightValue != null) {
                const newestValue = newestSensorValue(deepnessRightFrontValues[index], deepnessRightBackValues[index]);

                let date = new Date(newestValue['_time']);
                const timezoneOffset = date.getTimezoneOffset() / 60;
                date.setHours(date.getHours() - timezoneOffset);
                const time = date.toISOString().substring(0, 19);

                const sameExist = existingDeepnesses.find(deepness => deepness.time === time
                                                                        && deepness.sensor_id === 1);

                if (sameExist == null) {
                    const deepnessValue = rightValue;
                    const latitude = newestValue['location'].latitude;
                    const longitude = newestValue['location'].longitude;

                    deepness.deepness = deepnessValue;
                    deepness.sensor_id = 1;
                    deepness.latitude = latitude;
                    deepness.longitude = longitude;
                    deepness.time = time;

                    deepnesses.push(Object.assign({}, deepness));
                }
                else {
                    postMessage(true);
                }
            }
        }

        postMessage(['/deepness', deepnesses]);

        maxLength = Math.max(temperatureLeftValues.length, temperatureMiddleValues.length,
                             temperatureRightValues.length, temperatureREMValues.length);

        let temperatures = [];

        for (let index = 0; index < maxLength; index++) {
            let temperature = {};

            if (index < temperatureLeftValues.length) {
                let date = new Date(temperatureLeftValues[index]['_time']);
                const timezoneOffset = date.getTimezoneOffset() / 60;
                date.setHours(date.getHours() - timezoneOffset);
                const time = date.toISOString().substring(0, 19);

                const sameExist = existingTemperatures.find(temperature => temperature.time === time.substring(0, 19)
                                                                        && temperature.sensor_id === 0);

                if (sameExist == null) {
                    temperature.temperature = parseFloat(temperatureLeftValues[index]['_value']);
                    temperature.sensor_id = 0;
                    temperature.time = time;
                    temperature.latitude = temperatureLeftValues[index]['location'].latitude;
                    temperature.longitude = temperatureLeftValues[index]['location'].longitude;
                    temperatures.push(Object.assign({}, temperature));
                }
                else {
                    postMessage(true);
                }
            }

            if (index < temperatureMiddleValues.length) {
                let date = new Date(temperatureMiddleValues[index]['_time']);
                const timezoneOffset = date.getTimezoneOffset() / 60;
                date.setHours(date.getHours() - timezoneOffset);
                const time = date.toISOString().substring(0, 19);

                const sameExist = existingTemperatures.find(temperature => temperature.time === time.substring(0, 19)
                                                                            && temperature.sensor_id === 1);

                if (sameExist == null) {
                    temperature.temperature = parseFloat(temperatureMiddleValues[index]['_value']);
                    temperature.sensor_id = 1;
                    temperature.time = time;
                    temperature.latitude = temperatureMiddleValues[index]['location'].latitude;
                    temperature.longitude = temperatureMiddleValues[index]['location'].longitude;
                    temperatures.push(Object.assign({}, temperature));
                }
                else {
                    postMessage(true);
                }
            }

            if (index < temperatureRightValues.length) {
                let date = new Date(temperatureRightValues[index]['_time']);
                const timezoneOffset = date.getTimezoneOffset() / 60;
                date.setHours(date.getHours() - timezoneOffset);
                const time = date.toISOString().substring(0, 19);

                const sameExist = existingTemperatures.find(temperature => temperature.time === time.substring(0, 19)
                                                                            && temperature.sensor_id === 2);

                if (sameExist == null) {
                    temperature.temperature = parseFloat(temperatureRightValues[index]['_value']);
                    temperature.sensor_id = 2;
                    temperature.time = time;
                    temperature.latitude = temperatureRightValues[index]['location'].latitude;
                    temperature.longitude = temperatureRightValues[index]['location'].longitude;
                    temperatures.push(Object.assign({}, temperature));
                }
                else {
                    postMessage(true);
                }
            }

            if (index < temperatureREMValues.length) {
                let date = new Date(temperatureREMValues[index]['_time']);
                const timezoneOffset = date.getTimezoneOffset() / 60;
                date.setHours(date.getHours() - timezoneOffset);
                const time = date.toISOString().substring(0, 19);

                const sameExist = existingTemperatures.find(temperature => temperature.time === time.substring(0, 19)
                                                                        && temperature.sensor_id === 3);

                if (sameExist == null) {
                    temperature.temperature = parseFloat(temperatureREMValues[index]['_value']);
                    temperature.sensor_id = 3;
                    temperature.time = time;
                    temperature.latitude = temperatureREMValues[index]['location'].latitude;
                    temperature.longitude = temperatureREMValues[index]['location'].longitude;
                    temperatures.push(Object.assign({}, temperature));
                }
                else {
                    postMessage(true);
                }
            }
        }

        postMessage(['/temperature', temperatures]);
    });
};
