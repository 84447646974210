import React from 'react';
import L from 'leaflet';
import { Map, Marker, Popup, TileLayer, Polyline } from 'react-leaflet';
import { connect } from 'react-redux';
import { ContractSelect, ConstructionSiteSelect, showMessage, List, Excel,
         MapTiles, showNotice } from 'components';
import ConstructionSiteLatestSelect from './ConstructionSiteLatestSelect';
import { clearMasses, addMasses, addMass, removeMass, changeMass } from './Actions';
import { fetch, Socket, toRadians, toDegrees, toETRSTM35FIN, toWGS84,
         timer, paddedNumber, getRoadData, getRoadCoordinates } from '../utils';
import { fromJS } from 'immutable';
import { XYPlot, YAxis, LineSeries, Crosshair, DiscreteColorLegend } from 'react-vis';
import Sensors from './Sensors'
import "../../../node_modules/react-vis/dist/style.css";
import './Mass.css';
import TimeRange from '../timeRange/TimeRange'

const Graph = props => {
  const crosshair = props.crosshairValue[0] != null ?
    <div id='crosshair-data'>
      <span>{props.crosshairValue[0].time}</span>
      <br/>
      <span>{props.crosshairValue[0].massPerSquare} kg / m²</span>
    </div>
    : null;

  const crosshair2 = props.crosshairValue[0] != null ?
    <div id='crosshair-data'>
      <span>{props.crosshairValue[0].time}</span>
      <br/>
      <span>{props.crosshairValue[0].wholeMassPerSquare} kg / m²</span>
    </div>
    : null;

  const ITEMS = [
      {title: 'Saatu', color: "blue", strokeDasharray: "dashed"},
      {title: 'Tavoite', color: 'green', strokeDasharray: "dashed"},
    ];

  return (
    <div className='row'>
      <DiscreteColorLegend orientation="horizontal" width={300} items={ITEMS} />
      <div className='column'>
        <h4>kg / m²</h4>
        <XYPlot height={315} width={600} onMouseLeave={props.resetCrosshairValue}>
          <YAxis />
          <LineSeries color="blue" data={props.graphData}
                      onNearestX={props.setCrosshairValue}/>
          <LineSeries color="green" data={props.graphData2} />
          <Crosshair values={props.crosshairValue}>
            {crosshair}
          </Crosshair>
        </XYPlot>
      </div>
        <div className='column'>
        <h4>Kokonaisuus kg / m²</h4>
        <XYPlot height={315} width={600} onMouseLeave={props.resetCrosshairValue}>
          <YAxis />
          <LineSeries color="blue" data={props.graphWholeData} 
                      onNearestX={props.setCrosshairValue} />
          <LineSeries color="green" data={props.graphData2} />
          <Crosshair values={props.crosshairValue}>
            {crosshair2}
          </Crosshair>
        </XYPlot>
      </div>
    </div>
  );
}

const MapOrListOrGraph = props => {
  let masses = props.masses;

  if (props.site === null) masses = props.masses.clear();

  if (props.state === 0) return <MapView masses={masses} showLoadData={props.showLoadData}
                                         site={props.site} mapPaths={props.mapPaths}
                                         mapZoom={props.mapZoom} mapPosition={props.mapPosition}
                                         selectedPath={props.selectedPath} />;

  if (props.state === 2) return <Graph masses={masses} resetCrosshairValue={props.resetCrosshairValue}
                                       crosshairValue={props.crosshairValue} setCrosshairValue={props.setCrosshairValue}
                                       graphData={props.graphData} graphData2={props.graphData2}
                                       graphWholeData={props.graphWholeData} />;

  if (props.state === 3) return <Sensors selectMass={props.selectMass} />;

  let totalTruckMass = 0;
  let totalRoadMass = 0;
  let totalLevelingMass = 0;
  let totalSmallAreas = 0;

  masses.map(mass => {
    totalTruckMass += mass.get('truck_mass');
    totalRoadMass += mass.get('mass');
    totalLevelingMass += mass.get('leveling_mass');
    totalSmallAreas += mass.get('small_areas');
    return null;
  });

  return (
    <div>
      <table>
        <thead>
          <tr>
            <th></th>
            <th>
              Kuorma tonnit
            </th>
            <th>
              Kaista tonnit
            </th>
            <th>
              Tasoitus tonnit
            </th>
            <th>
              Pienalueet
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              <b>Yhteensä:</b>
            </td>
            <td>
              {Math.round(totalTruckMass*10000)/10000}
            </td>
            <td>
              {Math.round(totalRoadMass*10000)/10000}
            </td>
            <td>
              {Math.round(totalLevelingMass*10000)/10000}
            </td>
            <td>
              {Math.round(totalSmallAreas*10000)/10000}
            </td>
          </tr>
        </tbody>
      </table>
      <List emptyText={'Ei massoja'}
            header={['Urakoitsija', 'Aika', 'Aloitus-lopetus', 'Suunta / ajorata / kaista', 'Pituus', 'Ajoneuvo',
                     'Kuorman koko', 'Kaista tonnit', 'Tasoitus tonnit', 'Pienalueet (m²)',
                     'Huomiot', '', '']}
            fields={['organization.name', 'date#time', 'roadPaths', 'roadPositions', 'length', 'truck.register_number',
                    'truck_mass#round', 'mass#round', 'leveling_mass', 'small_areas', 'attentions',
                    'button|Näytä|0#id', 'button|Kuorma tiedot|1#load']}
            functions={[props.selectMassByMassId, props.showLoadData]}
            data={masses}/>
    </div>
  )
};

const LoadDataOfMass = props => {
  if (props.data.length === 0) return null;

  let totalStone1 = 0;
  let totalStone2 = 0;
  let totalStone3 = 0;
  let totalStone4 = 0;
  let totalBitumen = 0;
  let totalPoly = 0;
  let totalFiller = 0;
  let totalCrush = 0;
  let totalFibre = 0;
  let totalMass = 0;
  let totalSize = 0;

  for (let index in props.data) {
    totalStone1 += props.data[index]['stone1'];
    totalStone2 += props.data[index]['stone2'];
    totalStone3 += props.data[index]['stone3'];
    totalStone4 += props.data[index]['stone4'];
    totalBitumen += props.data[index]['bitumen'];
    totalPoly += props.data[index]['dust'];
    totalFiller += props.data[index]['filler'];
    totalCrush += props.data[index]['crush'];
    totalFibre += props.data[index]['fibre'];
    totalMass += props.data[index]['mass'];;
    totalSize += props.data[index]['size'];
  }

  return (
    <div onClick={props.clear} className='modal'>
      <div id='load-data-modal'>
        <h4 className='center'>Kuorman tiedot (Tonneina)</h4>
        <table>
          <thead>
            <tr>
              <th></th>
              <th>
                Kivi 1
              </th>
              <th>
                Kivi 2
              </th>
              <th>
                Kivi 3
              </th>
              <th>
                Kivi 4
              </th>
              <th>
                Bitumi
              </th>
              <th>
                Pöly
              </th>
              <th>
                Täyteaine
              </th>
              <th>
                Rouhe
              </th>
              <th>
                Kuitu
              </th>
              <th>
                Tonnit yhteensä
              </th>
              <th>
                Annoskoko
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <b>Yhteensä:</b>
              </td>
              <td>
                {Math.round(totalStone1*10000)/10000}
              </td>
              <td>
                {Math.round(totalStone2*10000)/10000}
              </td>
              <td>
                {Math.round(totalStone3*10000)/10000}
              </td>
              <td>
                {Math.round(totalStone4*10000)/10000}
              </td>
              <td>
                {Math.round(totalBitumen*10000)/10000}
              </td>
              <td>
                {Math.round(totalPoly*10000)/10000}
              </td>
              <td>
                {Math.round(totalFiller*10000)/10000}
              </td>
              <td>
                {Math.round(totalCrush*10000)/10000}
              </td>
              <td>
                {Math.round(totalFibre*10000)/10000}
              </td>
              <td>
                {Math.round(totalMass*10000)/10000}
              </td>
              <td>
                {Math.round(totalSize*10000)/10000}
              </td>
            </tr>
          </tbody>
        </table>
        <h5 className='center'>Erittely lastauksista:</h5>
        <List id='test'
              header={['Aika', 'Rekka', 'Resepti', 'Kivi 1', 'Kivi 2', 'Kivi 3',
                      'Kivi 4', 'Bitumi', 'Pöly', 'Täyteaine', 'Rouhe', 'Kuitu',
                      'Lämpötila', 'kg', 'Annoskoko', 'Huomiot']}
              fields={['time_stamp#time', 'truck', 'recipe', 'stone1#round', 'stone2#round',
                      'stone3#round', 'stone4#round', 'bitumen#round', 'dust#round', 'filler#round',
                      'crush#round', 'fibre#round', 'tempature#round', 'mass#round', 'size', 'attentions']}
              data={fromJS(props.data)}/>
      </div>
    </div>
  );
};

const LoadData = props => {
  if (props.loading) return <div className="loader"></div>;

  if (props.data.length === 0) return null;

  let totalStone1 = 0;
  let totalStone2 = 0;
  let totalStone3 = 0;
  let totalStone4 = 0;
  let totalBitumen = 0;
  let totalPoly = 0;
  let totalFiller = 0;
  let totalCrush = 0;
  let totalFibre = 0;
  let totalMass = 0;
  let totalSize = 0;

  for (let index in props.data) {
    totalStone1 += props.data[index]['stone1'];
    totalStone2 += props.data[index]['stone2'];
    totalStone3 += props.data[index]['stone3'];
    totalStone4 += props.data[index]['stone4'];
    totalBitumen += props.data[index]['bitumen'];
    totalPoly += props.data[index]['dust'];
    totalFiller += props.data[index]['filler'];
    totalCrush += props.data[index]['crush'];
    totalFibre += props.data[index]['fibre'];
    totalMass += props.data[index]['mass'];;
    totalSize += props.data[index]['size'];
  }

  return (
    <div>
      <h3 className='center'>Koko aine tiedot (Tonneina)</h3>
      <table>
        <thead>
          <tr>
            <th></th>
            <th>
              Kivi 1
            </th>
            <th>
              Kivi 2
            </th>
            <th>
              Kivi 3
            </th>
            <th>
              Kivi 4
            </th>
            <th>
              Bitumi
            </th>
            <th>
              Pöly
            </th>
            <th>
              Täyteaine
            </th>
            <th>
              Rouhe
            </th>
            <th>
              Kuitu
            </th>
            <th>
              Kg
            </th>
            <th>
              Annoskoko
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              <b>Yhteensä:</b>
            </td>
            <td>
            {Math.round(totalStone1*10000)/10000}
          </td>
          <td>
            {Math.round(totalStone2*10000)/10000}
          </td>
          <td>
            {Math.round(totalStone3*10000)/10000}
          </td>
          <td>
            {Math.round(totalStone4*10000)/10000}
          </td>
          <td>
            {Math.round(totalBitumen*10000)/10000}
          </td>
          <td>
            {Math.round(totalPoly*10000)/10000}
          </td>
          <td>
            {Math.round(totalFiller*10000)/10000}
          </td>
          <td>
            {Math.round(totalCrush*10000)/10000}
          </td>
          <td>
            {Math.round(totalFibre*10000)/10000}
          </td>
          <td>
            {Math.round(totalMass*10000)/10000}
          </td>
          <td>
            {Math.round(totalSize*10000)/10000}
          </td>
          </tr>
        </tbody>
      </table>
      <h4 className='center'>Erittelyt:</h4>
      <List id='test'
            header={['Aika', 'Rekka', 'Resepti', 'Kivi 1', 'Kivi 2', 'Kivi 3',
                     'Kivi 4', 'Bitumi', 'Pöly', 'Täyteaine', 'Rouhe', 'Kuitu',
                     'Lämpötila', 'Tonneina yhteensä', 'Annoskoko', 'Huomiot']}
            fields={['time_stamp#time', 'truck', 'recipe', 'stone1#round',
                    'stone2#round', 'stone3#round', 'stone4#round', 'bitumen#round', 'dust#round',
                    'filler#round', 'crush#round', 'fibre#round', 'tempature#round', 'mass#round', 'size', 'attentions']}
            data={fromJS(props.data)}/>
    </div>
  );
};


export class MapView extends React.Component {

  constructor(props){
    super(props);
    this.update = this.update.bind(this);
    this.updateDimensions = this.updateDimensions.bind(this);
    this.showLoadDataOfMass = this.showLoadDataOfMass.bind(this);
    this.clearLoadDataOfMass = this.clearLoadDataOfMass.bind(this);
    this.selectLine = this.selectLine.bind(this);

    this.position = [64.1, 26.5];
    this.zoom = 6;
    this.truckUpdateInterval = null;

    const height = window.innerHeight * 0.6;

    this.state = {
      loadDataOfMass: [],
      showedWarning: false,
      directions: [1, 2],
      lines: [],
      yourLaditude: null,
      yourLongitude: null,
      height: height,
      mapTilesUrl: '',
      mapTilesAttribution: '',
      maxZoom: 16
    };
  }

  async componentDidMount() {
    if (this.props.mapPosition) {
      this.position = this.props.mapPosition;
      this.zoom = this.props.mapZoom;
    }

    window.addEventListener("resize", this.updateDimensions);
    await this.update(this.props, this.state);

    if (!navigator.geolocation) {
      return;
    }

    this.watchID = navigator.geolocation.watchPosition(position => {
      this.setState({
        yourLaditude: position.coords.latitude,
        yourLongitude: position.coords.longitude
      });
    }, error => {
      return;
    }, {enableHighAccuracy: true});
  }

  componentWillUnmount() {
    navigator.geolocation.clearWatch(this.watchID);
    window.removeEventListener("resize", this.updateDimensions);
    clearInterval(this.truckUpdateInterval);
  }

  componentWillUpdate(nextProps, nextState) {
    const mapCenter = this.map.leafletElement.getCenter();
    const converted = toETRSTM35FIN(mapCenter.lat, mapCenter.lng)
    const mapTiles = MapTiles(converted.x, converted.y);

    if (this.state.mapTilesUrl !== mapTiles.url) {
      this.setState({
        mapTilesUrl: mapTiles.url,
        mapTilesAttribution: mapTiles.attribution,
        maxZoom: mapTiles.maxZoom
      });
    }

    if (this.state.selectedLine !== nextState.selectedLine ||
        this.state.directions !== nextState.directions ||
        this.props.mapPaths !== nextProps.mapPaths ||
        this.props.selectedPath !== nextProps.selectedPath) {
      this.update(nextProps, nextState);
    }
    
    if (this.props.mapPosition) {
      this.position = this.props.mapPosition;
      this.zoom = this.props.mapZoom;
    }
  }

  updateDimensions() {
    const height = window.innerHeight * 0.6;
    this.setState({ height: height })
  }

  async update(props, state) {
    if (props.mapPaths.length === 0) {
      this.setState({ lines: [] })
      return;
    }

    let lines = [];

    for (let i = 0; i < props.masses.size; i++) {
      const mass = props.masses.get(i);

      for (let p = 0; p < mass.get('paths').size; p++) {
        const path =  mass.get('paths').get(p);

        if (!(state.directions.includes(path.get('direction')))) continue;

        if (!props.mapPaths[path.get('id')]) continue;

        const date = new Date(path.get('date'));
        const time = date.getDate() + '.' + (date.getMonth() + 1) + '.' + date.getFullYear()
        + ' ' + paddedNumber(date.getHours()) + ':' + paddedNumber(date.getMinutes()) + ':' + paddedNumber(date.getSeconds());

        let color;

        if (state.selectedLine === path.get('id')) {
          color = '#00FFFF';
        }
        else if (path.get('direction') === 1) {
          color = '#0000FF';
        }
        else {
          color = '#00FF00';
        }

        const text = (
          <span>
            {time}
            <br/>
            Aloitus: {path.get('road')} / Osa: {path.get('start_part')} / Paalu: {path.get('start_distance')}
            <br/>
            Lopetus: {path.get('road')} / Osa: {path.get('end_part') || '- '} / Paalu: {path.get('end_distance') || '-'}
            <br/>
            Ajorata: {path.get('roadway')}
            <br/>
            Suunta: {path.get('direction')}
            <br/>
            Kaista: {path.get('lane')}
            <br/>
            { path.get('location_on_road') ?
              <div>
                Sijainti ajoradalla: {props.grooveValues['L' + path.get('location_on_road')]}
              </div>
              : null
            }
            Rekisterinumero: {mass.get('truck').get('register_number')}
            <br/>
            { mass.get('paths').size > 1 ?
              <div>
                Arvio käytetystä massasta: {Math.round(path.get('estimated_mass') * 100) / 100}
              </div>
              : null
            }
            Kuorman koko: {Math.round(mass.get('truck_mass') * 100) / 100}
            <br/>
            Kuorman kaista massa: {Math.round(mass.get('mass') * 100) / 100}
            <br/>
            { mass.get('load') != null ?
              <p className='center'>
                <button onClick={this.showLoadDataOfMass.bind(this, mass.get('load'))}>Kuorma tiedot</button>
              </p>
              :
              <p className='center'>
                Ei kuorma tietoja
              </p>
            }
          </span>
        );

        const mapPath = props.mapPaths[path.get('id')];

        if (mapPath.length === 1) {
          mapPath.push(mapPath[0]);
        }

        lines.push(
          <Polyline key={path.get('id')}
                    positions={mapPath}
                    weight={10}
                    opacity={0.7}
                    color={color}
                    onClick={this.selectLine.bind(null, path.get('id'))}
                    ref={ref => this.props.selectedPath === path.get('id') && setTimeout(() =>
                                  this.goToPath(ref, path.get('id')))}>
            <Popup autoPan={false}>
              {text}
            </Popup>
          </Polyline>
        );
      }
    }

    this.setState({ lines: lines })
  }

  goToPath(ref, id) {
    if (ref == null) return;
    const coordinates = ref.leafletElement.getLatLngs();
    const pos = coordinates[Math.floor(coordinates.length / 2)];
    this.map.leafletElement.setView([pos.lat, pos.lng], 14);
    timer(100).then(() => {
      ref.leafletElement.openPopup();
      this.selectLine(id);
    })
  }

  showLoadDataOfMass(id) {
    fetch('/coatingplantmasses?load=' + id).then(data => {
      this.setState({ 
        loadDataOfMass: data
      })
    }).catch(error => {
      this.props.showMessage('Virhe', 'Palvelimelta ei saatu asema tietoja', 'Error');
    });
  }

  clearLoadDataOfMass() {
    this.setState({ 
      loadDataOfMass: []
    })
  }

  changeDirection(value, event) {
    let directions = this.state.directions.slice();

    if (event.target.checked) {
      directions.push(value);
    }
    else {
      const index = directions.findIndex(direction => direction === value);
      directions.splice(index, 1);
    }

    this.setState({ directions: directions });
  }

  selectLine(id) {
    this.setState({
      selectedLine: id
    })
  }

  render() {
    return (
      <div>
        <div className='center'>
          {'Suunnat: '}
          <label>
            1
            <input id='direction1' type='checkbox' name='direction' value='1'
              onChange={this.changeDirection.bind(this, 1)}
              defaultChecked={this.state.directions.includes(1)}/>
          </label>
          <label>
            2
            <input id='direction2' type='checkbox' name='direction' value='2'
              onChange={this.changeDirection.bind(this, 2)}
              defaultChecked={this.state.directions.includes(2)}/>
          </label>
        </div>
        <div style={{ height: this.state.height }}>
          <link rel='stylesheet' href='https://unpkg.com/leaflet@1.4.0/dist/leaflet.css'
                integrity='sha512-puBpdR0798OZvTTbP4A8Ix/l+A4dHDD0DGqYW6RQ+9jxkRFclaxxQb/SJAWZfWAkuyeQUytO7+7N4QKrDh+drA=='
                crossOrigin='' />
          <Map id='map-area' center={this.position} zoom={this.zoom} maxZoom={this.state.maxZoom} onClick={this.selectLine.bind(null, null)}
               ref={element => this.map = element}>
            <TileLayer url={this.state.mapTilesUrl}
                       attribution={this.state.mapTilesAttribution}
                       maxZoom={this.state.maxZoom} />
            { this.props.site != null && this.props.site.get('coating_plant_laditude') != null ? (
                <Marker position={[this.props.site.get('coating_plant_laditude'), this.props.site.get('coating_plant_longitude')]}
                        icon={new L.Icon({iconUrl: 'coating_plant.gif',
                              iconSize: [33, 50],
                              iconAnchor: [17, 50],
                              popupAnchor: [null, -50]
                              })}>
                  <Popup autoPan={false}>
                    <span>{this.props.site.get('coating_plant_laditude')}, {this.props.site.get('coating_plant_longitude')}</span>
                  </Popup>
                </Marker>
              ) : null }
            { this.state.yourLaditude != null ? (
                <Marker position={[this.state.yourLaditude, this.state.yourLongitude]}
                        icon={new L.Icon({iconUrl: 'your_location.gif',
                              iconSize: [18, 43],
                              iconAnchor: [9, 43],
                              popupAnchor: [null, -43]
                              })}>
                  <Popup autoPan={false}>
                    <span>{this.state.yourLaditude}, {this.state.yourLongitude}</span>
                  </Popup>
                </Marker>
              ) : null }
            {this.state.lines}
          </Map>
        </div>
        {this.state.showedWarning ? 'Yhtä tai useampaa kuormaa ei voida näyttää sijainti tiedon virheellisyyden vuoksi.' : ''}
        <LoadDataOfMass data={this.state.loadDataOfMass} clear={this.clearLoadDataOfMass}/>
      </div>
    );
  }
};


class Masses extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      state: 0,
      loading: false,
      loadDataOfMass: [],
      loadData: [],
      loadingLoadData: false,
      makeExcel: false,
      makeExcelCoatingPlant: false,
      crosshairValue: [],
      mapPaths: []
    };

    this.changeState = this.changeState.bind(this);
    this.showLoadDataOfMass = this.showLoadDataOfMass.bind(this);
    this.clearLoadData = this.clearLoadData.bind(this);
    this.clearLoadDataOfMass = this.clearLoadDataOfMass.bind(this);
    this.getLoadData = this.getLoadData.bind(this);
    this.toggleMakeExcel = this.toggleMakeExcel.bind(this);
    this.toggleMakeExcelCoatingPlant = this.toggleMakeExcelCoatingPlant.bind(this);
    this.setCrosshairValue = this.setCrosshairValue.bind(this);
    this.resetCrosshairValue = this.resetCrosshairValue.bind(this);
    this.selectMass = this.selectMass.bind(this);
    this.selectMassByMassId = this.selectMassByMassId.bind(this);
    this.toggleSelectingSite = this.toggleSelectingSite.bind(this);
  }

  componentDidMount() {
    if (this.socket == null && typeof(WebSocket) !== 'undefined') {
      this.socket = Socket('/data/mass');
      this.socket.onmessage = function(e) {
        const data = JSON.parse(e.data);
        if (data['operation'] === 'create') {
          if (data.model.construction_site) {
            if (this.props.selectedConstructionSite.get('id') !== data.model.constructionSiteId) {
              return;
            }
          }
          else {
            const exist = this.props.masses.find(mass => mass.paths_id === data.model.paths_id);
            if (!exist) {
              return;
            }
          }

          if (this.props.timeRangeStart !== '' && this.props.timeRangeEnd !== '') {
            this.getMasses(this.props.selectedConstructionSite.get('id'), this.props.timeRangeStart, this.props.timeRangeEnd);
          }
          else {
            this.getMasses(this.props.selectedConstructionSite.get('id'));
          }
        }
        else if (data['operation'] === 'update') {
          if (data.model.construction_site) {
            if (this.props.selectedConstructionSite.get('id') !== data.model.construction_site.id) {
              return;
            }
            this.props.changeMass(data.model);
          }
          else {
            const exist = this.props.masses.find(mass => mass.paths_id === data.model.paths_id);
            if (exist) {
              if (this.props.timeRangeStart !== '' && this.props.timeRangeEnd !== '') {
                this.getMasses(this.props.selectedConstructionSite.get('id'), this.props.timeRangeStart, this.props.timeRangeEnd);
              }
              else {
                this.getMasses(this.props.selectedConstructionSite.get('id'));
              }
            }
          }
        }
        else if (data['operation'] === 'delete') {
          this.props.removeMass(data.model);
        }
      }.bind(this)
    }

    if (!this.props.selectedConstructionSite) return;

    if (this.props.timeRangeStart !== '' && this.props.timeRangeEnd !== '') {
      this.getMasses(this.props.selectedConstructionSite.get('id'), this.props.timeRangeStart, this.props.timeRangeEnd);
    }
    else {
      this.getMasses(this.props.selectedConstructionSite.get('id'));
    }
  }

  componentDidUpdate(lastProps, lastState) {
    if ((this.state.selectingSite && !lastState.selectingSite) ||
        (this.props.selectedConstructionSite === lastProps.selectedConstructionSite &&
        this.props.timeRangeStart === lastProps.timeRangeStart &&
        this.props.timeRangeEnd === lastProps.timeRangeEnd)) return;

    this.props.clearMasses();
    this.clearLoadData();
    this.clearGraph();

    if (this.props.selectedContract == null || this.props.selectedConstructionSite == null) {
      return;
    }
    
    if (this.props.timeRangeStart !== '' && this.props.timeRangeEnd !== '') {
      this.getMasses(this.props.selectedConstructionSite.get('id'), this.props.timeRangeStart, this.props.timeRangeEnd);
    }
    else {
      this.getMasses(this.props.selectedConstructionSite.get('id'));
    }
  }

  componentWillUnmount() {
    if (this.socket != null) this.socket.close();
  }

  async getMapPaths(masses, site) {
    this.setState({ mapPaths: [] });

    let paths = [];
    let x = 0;
    let y = 0;
    let z = 0;
    let coordinateCount = 0;
    let zoom = null;
    let position = null;
    let allPoints = [];

    if (site != null) {
      try {
        allPoints = await fetch('/points/mass/site/' + site);
      } catch(err) {}
    }

    for (let index in masses) {
      const mass = masses[index];

      for (let pathIndex in mass.paths) {
        const path =  mass.paths[pathIndex];

        if (!path.start_latitude) continue;

        const startLatitude = toRadians(path.start_latitude);
        const startLongitude = toRadians(path.start_longitude);
        x += Math.cos(startLatitude) * Math.cos(startLongitude);
        y += Math.cos(startLatitude) * Math.sin(startLongitude);
        z += Math.sin(startLatitude);
        coordinateCount++;

        let positions = [];

        if (path.end_latitude) {
          const allPathPoint = allPoints.filter(point => point.path_id === path.id);

          if (allPathPoint.length !== 0) {
            allPathPoint.forEach(point => {
              if (point.latitude) {
                positions.push([point.latitude, point.longitude])
              }
            });
            if (allPathPoint[allPathPoint.length - 1].road_distance !== path.end_distance) {
              positions.push([path.end_latitude, path.end_longitude])
            }
          }
          else {
            positions = [[path.start_latitude, path.start_longitude],
                        [path.end_latitude, path.end_longitude]];
          }

          const endLatitude = toRadians(path.end_latitude);
          const endLongitude = toRadians(path.end_longitude);
          x += Math.cos(endLatitude) * Math.cos(endLongitude);
          y += Math.cos(endLatitude) * Math.sin(endLongitude);
          z += Math.sin(endLatitude);
          coordinateCount++;
        }
        else {
          positions = [[path.start_latitude, path.start_longitude],
                      [path.start_latitude, path.start_longitude]];
        }

        if (path.direction === 2) {
          if (path.start_part > path.end_part ||
              path.start_distance > path.end_distance) {
            positions.reverse();
          }
          positions = await this.get2DirectionPath(positions);
        }

        paths[path.id] = positions;
      }
    }

    if (coordinateCount !== 0) {
      zoom = 15;

      x = x / coordinateCount;
      y = y / coordinateCount;
      z = z / coordinateCount;

      const centralLongitude = Math.atan2(y, x);
      const centralSquareRoot = Math.sqrt(x * x + y * y);
      const centralLatitude = Math.atan2(z, centralSquareRoot);

      position = [centralLatitude * 180 / Math.PI, centralLongitude * 180 / Math.PI];
    }
    else {
      zoom = 6;
      position = [64.1, 26.5];
    }

    this.setState({
      mapPaths: paths,
      mapZoom: zoom,
      mapPosition: position
    });
  }

  async get2DirectionPath(path) {
    let newPath = []
    let lastAngle;

    if (path.length > 1 && path[0][0] !== path[1][0]) {
      for (let index in path) {
        index = parseInt(index, 10);
        const point = path[index];

        if (index !== path.length - 1) {
          const point2 = path[index + 1];
          lastAngle = this.getOffSetAngle(point[0], point[1], point2[0], point2[1]);
        }

        const newCoordinate = this.getNewCoordinatesByAngle(lastAngle, point[0], point[1])
        newPath.push(newCoordinate)
      }
    }
    else {
      try {
        const converted = toETRSTM35FIN(path[0][0], path[0][1]);
        let roadData = await getRoadData(converted.y, converted.x, 10);
        const roadNumber = roadData.road;
        const roadPart = roadData.part;
        let roadDistance = roadData.distance;
        const anotherPointDistance = 10;

        if (roadDistance < anotherPointDistance) {
          roadDistance = roadDistance + anotherPointDistance;
        }
        else {
          roadDistance = roadDistance - anotherPointDistance;
        }

        let coordinates = await getRoadCoordinates(roadNumber, roadPart, roadDistance);

        const anotherCoordinates = toWGS84(coordinates.y, coordinates.x);

        const angle = this.getOffSetAngle(anotherCoordinates.latitude, anotherCoordinates.longitude,
                                          path[0][0], path[0][1]);
                
        const newCoordinate = this.getNewCoordinatesByAngle(angle, path[0][0], path[0][1])
        newPath = [newCoordinate, newCoordinate]
      } catch(error) {
        return path;
      }
    }

    return newPath;
  }

  getOffSetAngle(lat1, lon1, lat2, lon2) {
    const dLon = lon2 - lon1;
    const y = Math.sin(dLon) * Math.cos(lat2);
    const x = Math.cos(lat1) * Math.sin(lat2) - Math.sin(lat1) *
              Math.cos(lat2) * Math.cos(dLon);
    let angle = Math.atan2(y, x);
    angle = toDegrees(angle);
    angle = (angle + 360 - 90) % 360;
    return toRadians(angle);
  }

  getNewCoordinatesByAngle(angle, latitude, longitude) {
    const R = 6378100; // Radius of the Earth
    const distanceBetween = 5;

    const lat1 = toRadians(latitude);
    const lon1 = toRadians(longitude);

    let newLatitude = Math.asin(Math.sin(lat1) * Math.cos(distanceBetween / R) +
                Math.cos(lat1) * Math.sin(distanceBetween / R) * Math.cos(angle));

    let newLongitude = lon1 + Math.atan2(Math.sin(angle) * Math.sin(distanceBetween / R) * Math.cos(lat1),
                                      Math.cos(distanceBetween / R) - Math.sin(lat1) * Math.sin(newLatitude));

    newLatitude = toDegrees(newLatitude);
    newLongitude = toDegrees(newLongitude);

    return [newLatitude, newLongitude];
  }

  async getMasses(site, startTime, endTime) {
    this.setState({
      loading: true
    });

    const allPaths = await fetch('/paths/mass/site/' + site);

    let url;

    if (startTime != null) {
      url = '/masses?site=' + site + '&timestart=' + startTime
            + '&timeend=' + endTime;
    }
    else {
      url = '/masses?site=' + site;
    }

    fetch(url).then(async data => {
      let graphData = [];
      let graphData2 = [];
      let graphWholeData = [];
      let calculatedMasses = [];
      let currentWholeMass = 0;
      let currentWholeArea = 0;
      let currentCount = 0;

      for (let index in data.reverse()) {
        let mass = data[index];

        if (mass.paths_id) {
          try {
            const paths = allPaths.filter(path => path.paths_id === mass.paths_id);
            let massWholeArea = 0;
            let roadPaths = '';
            let roadPositions = '';
            let length = 0;

            for (let index in paths) {
              const path = paths[index];

              if (path.area) {
                massWholeArea += path.area;
              }

              roadPaths += path.start_part + ' / ' + path.start_distance + ' - ' +
                           (path.end_part ? (path.end_part + ' / ' + path.end_distance) + ' , ' : '');
              roadPositions += path.direction + ' / ' + path.roadway + ' / ' + path.lane +
                               ' , ';
              length += path.length;
            }

            mass.roadPaths = roadPaths.substring(0, roadPaths.length - 3);
            mass.roadPositions = roadPositions.substring(0, roadPositions.length - 3);
            mass.length = length;

            if (massWholeArea !== 0) {
              let weightedSumMassPerSquare = 0;

              for (let index in paths) {
                const path = paths[index];
                if (path.area) {
                  const ratio = path.area / massWholeArea;
                  weightedSumMassPerSquare += path.mass_per_square * ratio;

                  const estimatedMass = path.area / massWholeArea * mass.mass;
                  path.estimated_mass = estimatedMass;
                  path.calculated_kg_per_square = Math.round(estimatedMass * 1000 / path.area);
                  paths[index] = path;
                }
              }

              const calculatedKgPerSquare = mass.mass * 1000 / massWholeArea;
              mass.calculated_kg_per_square = Math.round(calculatedKgPerSquare);
              graphData.push({x: currentCount, y: calculatedKgPerSquare});
              graphData2.push({x: currentCount, y: weightedSumMassPerSquare});
              currentWholeMass += mass.mass;
              currentWholeArea += massWholeArea;
              currentCount++;
              graphWholeData.push({x: currentCount - 1, y: currentWholeMass * 1000 / currentWholeArea});
              calculatedMasses.push(mass);
            }

            mass.paths = paths;
          } catch(error) {
            mass.paths = [];
          }
        }
        else {
          mass.paths = [];
        }
      }

      this.getMapPaths(data, site);
      this.props.addMasses(data);
      this.setState({
        graphData: graphData,
        graphData2: graphData2,
        graphWholeData: graphWholeData,
        calculatedMasses: calculatedMasses,
        loading: false
      });
    }).catch(error => {
      this.props.showMessage('Virhe', 'Palvelimeen ei saatu yhteyttä', 'Error');
    });

    this.getLoadData(site, startTime, endTime);
  }

  getLoadData(site, startTime, endTime) {
    this.setState({ loadingLoadData: true });

    let url;
    
    if (startTime != null) {
      url = '/coatingplantmasses?site=' + site + '&starttime=' + startTime
            + '&endtime=' + endTime;
    }
    else {
      url = '/coatingplantmasses?site=' + site;
    }

    fetch(url).then(data => {
      this.setState({ 
        loadData: data
      })
    }).catch(error => {

    }).then(() => {
      this.setState({ loadingLoadData: false })
    });
  }

  changeState(state) {
    this.setState({
      state: state
    });
  }

  showLoadDataOfMass(id) {
    fetch('/coatingplantmasses?load=' + id).then(data => {
      this.setState({ 
        loadDataOfMass: data
      });
    }).catch(error => {
      this.props.showMessage('Virhe', 'Palvelimelta ei saatu aseman tietoja', 'Error');
    });
  }

  clearLoadData() {
    this.setState({ 
      loadData: []
    })
  }

  clearLoadDataOfMass() {
    this.setState({ 
      loadDataOfMass: []
    })
  }

  clearGraph() {
    this.setState({ 
      graphData: [],
      graphData2: [],
      graphWholeData: [],
    })
  }

  toggleMakeExcel() {
    this.setState({ 
      makeExcel: !this.state.makeExcel
    })
  }

  toggleMakeExcelCoatingPlant() {
    this.setState({ 
      makeExcelCoatingPlant: !this.state.makeExcelCoatingPlant
    })
  }

  setCrosshairValue(value, {index}) {
    const mass = this.state.calculatedMasses[index];
    const date = new Date(mass['date']);
    const time = date.getDate() + '.' + (date.getMonth() + 1) + '.' + date.getFullYear()
      + ' ' + paddedNumber(date.getHours()) + ':' + paddedNumber(date.getMinutes()) + ':' + paddedNumber(date.getSeconds());

    this.setState({
      crosshairValue: [{
         x: index,
         time: time,
         massPerSquare: Math.round(mass['calculated_kg_per_square'] * 100) / 100,
         wholeMassPerSquare: Math.round(this.state.graphWholeData[index]['y'] * 100) / 100
      }]
    });
  }

  resetCrosshairValue() {
    this.setState({crosshairValue: []});
  }

  selectMass(sensorData) {
    const selectedMass = this.props.masses.find(
      mass => mass.get('id') === sensorData.get('mass_id'));

    if (selectedMass == null) {
      this.props.showNotice('Ei yhdistettyä massaa', 'Warning');
      return;
    }
    
    this.setState({
      state: 0
    }, () => {
      let selectedPath;

      for (let path of selectedMass.get('paths')) {
        if (new Date(sensorData.get('time')) - new Date(path.get('date'))) {
          selectedPath = path.get('id');
        }
        else {
          break;
        }
      }

      this.setState({
        selectedPath: selectedPath
      }, () => {
        this.setState({
          selectedPath: null
        });
      });
    });
  }

  selectMassByMassId(massId) {
    this.setState({
      state: 0
    }, () => {
      const selectedMass = this.props.masses.find(
        mass => mass.get('id') === massId);

      this.setState({
        selectedPath: selectedMass.get('paths').first().get('id')
      }, () => {
        this.setState({
          selectedPath: null
        });
      });
    });
  }

  async toggleSelectingSite() {
    await this.setState({ selectingSite: !this.state.selectingSite });
  }
  render() {
    let id = null;
    let name = null;

    if (this.props.selectedConstructionSite != null) {
       id = this.props.selectedConstructionSite.get('id');
       name = this.props.selectedConstructionSite.get('name');
    }

    return (
      <div>
        <div className="center">
          <h1>Massat</h1>
          <ConstructionSiteLatestSelect get='masses' toggleSelectingSite={this.toggleSelectingSite} />
        </div>
        <div className='container'>
          <div className="row">
            <div className="column">
              <ContractSelect store={this.props.store} />
            </div>
            <div className="column">
              <ConstructionSiteSelect store={this.props.store} />
            </div>
          </div>
          <div className="row">
            <div className="column">
              <TimeRange />
            </div>
            <div className="column">
              <button onClick={this.toggleMakeExcel} disabled={id == null || this.props.masses.size === 0}>Luo raportti</button>
            </div>
            <div className="column">
              <button onClick={this.toggleMakeExcelCoatingPlant} disabled={id == null || this.props.masses.size === 0}>Luo asema raportti</button>
            </div>
          </div>
        </div>
        <div className='wide-area'>
          <fieldset id="data">
            <legend>
              <div>
                <div className={"state" + (this.state.state === 0 ? ' selected' : '')}
                onClick={this.state.state === 0 ? null : this.changeState.bind(null, 0)}>
                  Kartta
                </div>
                <div className={"state" + (this.state.state === 1 ? ' selected' : '')}
                onClick={this.state.state === 1 ? null : this.changeState.bind(null, 1)}>
                  Lista
                </div>
                <div className={"state" + (this.state.state === 2 ? ' selected' : '')}
                      onClick={this.state.state === 2 ? null : this.changeState.bind(null, 2)}>
                  Graafi
                </div>
                <div className={"state" + (this.state.state === 3 ? ' selected' : '')}
                      onClick={this.state.state === 3 ? null : this.changeState.bind(null, 3)}>
                  Sensoritiedot
                </div>
              </div>
            </legend>
            <MapOrListOrGraph state={this.state.state} masses={this.props.masses} site={this.props.selectedConstructionSite}
                      loading={this.state.loading} page={this.state.page} changePage={this.changePage}
                      showLoadData={this.showLoadDataOfMass} resetCrosshairValue={this.resetCrosshairValue}
                      crosshairValue={this.state.crosshairValue} setCrosshairValue={this.setCrosshairValue}
                      graphData={this.state.graphData} graphData2={this.state.graphData2}
                      graphWholeData={this.state.graphWholeData} paths={this.state.paths}
                      mapPaths={this.state.mapPaths} mapZoom={this.state.mapZoom}
                      mapPosition={this.state.mapPosition} selectMass={this.selectMass}
                      selectedPath={this.state.selectedPath} selectMassByMassId={this.selectMassByMassId} />
          </fieldset>
          <hr/>
          <LoadData data={this.state.loadData} loading={this.state.loadingLoadData}/>
          <LoadDataOfMass data={this.state.loadDataOfMass} clear={this.clearLoadDataOfMass}/>
          { this.state.loading ? <div className="main loader"/> : null }
        </div>
        { name != null ?
          <div>
            <Excel show={this.state.makeExcel} toggle={this.toggleMakeExcel}
                      name={name.length > 31 ? id.toString() : name}
                      headers={['Kohde', 'Aika', 'Aloitus - lopetus', 'Suunta / ajorata / kaista', 'Rekka',
                                'Kuorman massa', 'Tiehen käytetty massa', 'Tasoitus massa', 'Pienalueet (m²)', 'Pituus (m)',
                                'Kg / m²', 'Saatu Kg / m²', 'Huomiot']}
                      dataHeaders={['construction_site.name', 'date', 'roadPaths', 'roadPositions', 'truck.register_number',
                                    'truck_mass', 'mass', 'leveling_mass', 'small_areas', 'length',
                                    'mass_per_square', 'calculated_kg_per_square', 'attentions']}
                      timeField={'date'}
                      data={this.props.masses}/>
            <Excel show={this.state.makeExcelCoatingPlant} toggle={this.toggleMakeExcelCoatingPlant}
                      name={(name.length > 31 ? id.toString() : name) + ' aseman data'}
                      headers={['Resepti', 'Aika', 'Rekka', 'Kivi 1', 'Kivi 2', 'Kivi 3', 'Kivi 4', 'Bitumi',
                                'Pöly', 'Täyteaine', 'Rouhe', 'Kuitu', 'Lämpötila', 'Tonnit', 'Koko', 'Huomiot']}
                      dataHeaders={['recipe', 'time_stamp', 'truck', 'stone1', 'stone2', 'stone3', 'stone4', 'bitumen',
                                    'dust', 'filler', 'crush', 'fibre', 'tempature', 'mass', 'size', 'attentions']}
                      timeField={'time_stamp'}
                      data={fromJS(this.state.loadData)}/>
          </div>
          :
          null
        }
      </div>
    );
  }
}

export default connect(state => ({
  selectedContract: state.contractSelect.get('selectedContract'),
  selectedConstructionSite: state.constructionSiteSelect.get('selectedConstructionSite'),
  timeRangeStart: state.timeRange.get('startTime'),
  timeRangeEnd: state.timeRange.get('endTime'),
  masses: state.mass.get('masses'),
  organizationId: state.login.get('user') ? state.login.get('user').get('organizationId') : null,
}), { clearMasses, addMasses, showMessage, addMass, removeMass, changeMass,
      showNotice })(Masses);
