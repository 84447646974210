export const clearMasses = () => ({
  type: 'CLEAR_MASSES'
});


export const addMasses = masses => ({
  type: 'ADD_MASSES',
  masses
});


export const removeMass = mass => ({
  type: 'REMOVE_MASS',
  mass
});


export const addMass = mass => ({
  type: 'ADD_MASS',
  mass
});


export const changeMass = mass => ({
  type: 'CHANGE_MASS',
  mass
});
